import React from 'react'
export default function AboutUs() {
  return (
    <section id="about-us" className={'content-wrap'}>
      <div className={'content'}>
          <h1 className={'content-title'}>About us &#8211; it’s not only the Code</h1>
          <span className="border"/>
          <p>
            BSolut GmbH, changing the game since 2008, founded by Daniel Rudolph in Berlin, consists of a
            multi-disciplinary team working side by side solving complex tasks every day. We have a successful track
            record at building StartUps, inventing software, and helping founders to accomplish their goals.
            <br/> <br/>
            We focus on diversity and skills and as we keep our team lean we do the same with projects. Teams are often
            inundated with too many emails, calls, meetings. We keep it simple. We&nbsp;focus. We&nbsp;automate. We thrive on
            challenges.
            <span style={{color: "#f5f5f5"}}>We are no agency!</span>
          </p>
      </div>
    </section>
  )
}
import React from 'react'
import Image from "next/image";

export default function WhatSetsUsApart() {
  let themes = [
    {
      title: 'Consultation',
      img_width: 45,
      img: 'comments.svg',
      text: <p>We have an experienced and dedicated team of experts</p>
    }, {
      title: 'Design',
      img: 'wand-magic-sparkles.svg',
      text: <p>We offer innovative and specialised solutions with market leading product features</p>
    }, {
      title: 'User Experience',
      img: 'laptop.svg',
      img_width: 50,
      text: <p>We offer tailored and sustainable pricing &#8211; there are no unnecessary management layers to support</p>
    }, {
      title: 'Development',
      img: 'gear.svg',
      text: <p>Our knowledge of these unique challenges and exposures allows us to offer enhanced solutions</p>
    },
  ]

  return (
    <section id="whatsetsusapart" className={'content-wrap white'}>
      <div className={'content'}>
        <h1 className={'content-title'}>What sets us apart?</h1>
        <span className="border"/>
        <p>
          We tackle business problems with intelligence. We use cutting-edge tech and cutting-edge thinking to bring
          brands to life online and direct consumer behaviour.
        </p>
      </div>

      <div id={'themes-list'} className={'flex-container'}>
        {themes.map(theme => {
          return <div key={"theme-"+theme.title} className={'theme-item'}>
            <Image width={theme.img_width || 40} height={40} src={`/images/icons/${theme.img}`} alt={theme.title} />
            <h2>{theme.title}</h2>
            {theme.text}
          </div>
        })}
      </div>
    </section>
  ) }
'use client';

import React, {useEffect, useState} from 'react'
import Link from 'next/link'
import Image from "next/image";
import {scrollTo, toggleView} from "/app/lib/_helper";

export default function Header(props) {
    //TODO bold on current position
    let [fixed, setFixed] = useState(!props.landing);

    useEffect(() => {
        let listener;
        if (props.landing) { //special scrolling on landing site as main img is above nav
            const nav = document.getElementById('navigation'),
                main_intro = document.getElementById('main-intro'),
                breaking_point = main_intro.clientTop + main_intro.clientHeight;

            listener = () => {
                let position = window.scrollY;

                //Navigation fixed After Scrolling below main image
                if (position >= breaking_point && !nav.classList.contains('fixed')) {
                    setFixed(true)
                } else if (position <= breaking_point && nav.classList.contains('fixed'))
                    setFixed(false)
            }
            window.addEventListener('scroll', listener, {passive: true});
        }

        return () => {
            if (listener)
                window.removeEventListener('scroll', listener);
        }
    }, [])

    useEffect(() => {
        if (fixed) document.getElementById('navigation').classList.add('fixed');
        else document.getElementById('navigation').classList.remove('fixed')
    }, [fixed])

    return (
        <>
            {fixed && <div id={'nav-puffer'}/>}
            <div id="navigation" className={'desktop ' + (fixed ? 'fixed' : '')}>
                <div className="navbar flex-container">
                    <Logo {...props} />

                    <ul id="nav-items" className={'push-right'}>
                        <NavItems {...props} />
                    </ul>

                    <Image width={22} height={19.5}
                           onClick={() => toggleView(document.getElementById('nav-mobile'))}
                           id={'nav-mobile-btn'}
                           className={'table clickable push-right'}
                           src={"/images/icons/bars.svg"} alt={'bars'} />

                    <div id={'nav-mobile'} style={{display: 'none'}}>
                        <ul onClick={() => toggleView(document.getElementById('nav-mobile'))}>
                            <NavItems {...props} />
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

const Logo = (props) => {
    const image = <img
        src="/images/bsolut_logo.webp"
        width={"250px"}
        height={'41px'}
        alt={'logo'}/>;

    return !props.landing ?
            <Link href={'/'} id="logo" className={'clickable push-left'}>
                {image}
            </Link>
            : <span id="logo" className={'clickable push-left'} onClick={() => scrollTo('main-intro', false)}>
            {image}
        </span>
}

const NavItems = (props) => {
    let nav_items = [
        {
            label: 'Home',
            scrollto: 'main-intro'
        }, {
            label: 'About us',
            scrollto: 'about-us'
        }, {
            label: 'Create what you want',
            scrollto: 'create'
        }, {
            label: 'Partners',
            scrollto: 'partners'
        }, {
            label: 'Career',
            scrollto: 'career'
        }, {
            label: 'Contact',
            scrollto: 'contact'
        },
    ];

    return nav_items.map(item => {
        return <li key={'navitem-' + item.scrollto}>
            {!props.landing ?
                <Link href={`/?view=${item.scrollto}`}>{item.label}</Link>
                : <span onClick={() => scrollTo(item.scrollto, item.scrollto !== 'main-intro')}>
                    {item.label}
                </span>
            }
        </li>
    })
}
import React from 'react'

export default function Partners() {
  let partners = [
    {
      title: 'Unikrn',
      name: 'unikrn',
      image: 'unikrn-logo',
      link: 'https://unikrn.com/',
      details: <p>
        Leading betting platform in eSports<br/>
        <span>Partner since 2014</span>
      </p>
    }, {
      title: 'Deutsche Bahn',
      name: 'db',
      image: 'db-logo',
      link: 'https://bahn.de/',
      details: <p>
        Deutsche Bahn AG<br/>
        <span>Partner since 2015</span>
      </p>
    }, {
      title: 'Gestalten',
      name: 'gestalten',
      image: 'gestalten-logo',
      link: 'https://gestalten.com/',
      details: <p>
        Publisher, specialized in Art illustrations focused on Design<br/>
        <span>Partner since 2012</span>
      </p>
    }, {
      title: 'Crosslend',
      name: 'crosslend',
      image: 'crosslend-logo',
      link: 'https://www.crosslend.com/',
      details: <p>
        Creditplatform for investors and borrower<br/>
        <span>Partner since 2015 </span>
      </p>
    }, {
      title: 'Flatnut',
      name: 'flatnut',
      image: 'flatnut-logo',
      link: 'https://flatnut.com/',
      details: <p>
        Consulting, analysis and development and implementation of digital business processes<br/>
        <span>Partner since 2017</span>
      </p>
    },
  ]

  return (
    <section id="partners" className={'content-wrap white'}>
      <div className={'content'}>
        <h1 className={'content-title'}>Partners</h1>
        <span className="border"/>
        <p>
          We focus on the challenge, not on the industry. Our partners come from various different industries such as
          internet and gaming, e-commerce, legal, public transport and film...
        </p>
      </div>

      <div id={'partners-list'} className={'flex-container'}>
        {partners.map(partner => {
          return <a title={partner.title}
                    rel="noreferrer"
                    key={'partner-' + partner.title}
                    href={partner.link}
                    target={'_blank'}
                    className={`partners-list-item partner-${partner.name}`}>
            <div
              style={{
                backgroundImage: `url('/images/partners/${partner.image}.webp')`,
                backgroundSize: partner.title === 'Flatnut' ? 'cover' : 'contain'
              }}
              className={'partner-image'}>
              <div className="partner-detail">
                {partner.details}
              </div>
            </div>
          </a>
        })}
      </div>
    </section>
  )
}
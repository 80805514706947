'use client';
import React, {useEffect} from 'react'
import Script from "next/script";

export default function Map() { //TODO map to be always present (so no multiple loads issue)
    useEffect(() => {
        let map, marker;
        if (typeof window !== 'undefined')
            window.initMap = () => {
                let latling = {lat: 52.54310775267354, lng: 13.422680385168212},
                    map_options = {
                        center: latling,
                        zoom: 11,
                        mapTypeControl: false,
                        styles: [{
                            featureType: "poi",
                            elementType: "labels",
                            stylers: [
                                {visibility: "off"}
                            ]
                        }]
                    };

                map = new google.maps.Map(document.getElementById("map"), map_options);
                marker = new google.maps.Marker({
                    position: latling,
                    title: 'BSOLUT',
                    icon: '/images/icons/map_marker.webp'
                });

                marker.setMap(map);
            }
    }, [])

    return (
        <>
            <div id={'map'}/>
            <Script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCRmGdkkk6Sykmv9JVz3TjJ12EuR_8NMxM&callback=initMap" />
        </>
    )
}
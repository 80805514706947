'use client';
import React, {useEffect} from 'react';

import Header from './lib/Header';
import {URLS, scrollTo} from "./lib/_helper";

import MainIntro from './lib/LandingParts/MainIntro';
import AboutUs from './lib/LandingParts/AboutUs';
import Create from './lib/LandingParts/Create';
import Partners from './lib/LandingParts/Partners';
import WhatSetsUsApart from './lib/LandingParts/WhatSetsUsApart';
import Career from './lib/LandingParts/Career';
import Contact from './lib/LandingParts/Contact';
import Map from './lib/LandingParts/Map';


export default function Page() {
    useEffect(() => {
        //Todo improve
        const scrollto_id = URLS.getUrlParameter('view');
        if(scrollto_id) {
            const element = document.getElementById(scrollto_id);
            if(element) {
                scrollTo(scrollto_id, scrollto_id !== 'main-intro');
                URLS.clearUrlParams();
            }
        }
    }, []);

    return <div id={'main'}>
        <MainIntro/>
        <Header landing/>

        <div className={`site active`}>
            <AboutUs/>
            <section className={'filler'}/>
            <Create/>
            <section className={'filler'}/>
            <Partners/>

            <section id="what-you-see">
                <h1>
                    <span> Like what you see? </span>
                    <button onClick={(e) => scrollTo('contact', true, e)}>
                        Contact us
                    </button>
                </h1>
            </section>

            <WhatSetsUsApart/>
            <Career/>
            <Contact/>

            <Map />
        </div>
    </div>
}
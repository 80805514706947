import React from 'react'
import {scrollTo} from "/app/lib/_helper";

export default function Career() {
  return (
    <section id="career" className={'content-wrap'}>
      <div className={'content'}>
        <h1 className={'content-title'}>Career</h1>
        <span className="border"/>
        <p> Are you dedicated to amazing products? </p>
        <p> Do you want to be involved in everything new? </p>
        <p> Do you strive to do the right thing while having fun? </p>
        <p> Do you combine high achievement with a distinctively low ego? </p>

        <button onClick={() => scrollTo('contact')}>Contact Us</button>
      </div>
    </section>
  )
}
"use client";
import React, {useEffect} from 'react'
import {Parallax, Background} from 'react-parallax';
import Image from 'next/image';

export default function MainIntro() {
    let buzz_slider;
    useEffect(() => {
        handleTextSlide();
        return () => {
            clearInterval(buzz_slider);
        }
    }, [])

    //Slider for Buzzwords
    const handleTextSlide = () => { //todo maybe reset on resize
        let slide = document.getElementById('text-slider'),
            counter = 1, current_top = 0;
        if(!slide) return;

        let clientHeight;
        [...document.getElementsByClassName("text-slide-wrap")].forEach(el => {
            clientHeight = slide.querySelector(`li:first-child`).clientHeight;
            el.style.height = clientHeight ? clientHeight + 'px' : '85px';
        });

        buzz_slider = setInterval(() => {
            let new_top = (current_top - slide.querySelector(`li:nth-child(${counter})`).clientHeight);
            slide.animate(
                [
                    // keyframes
                    { transform: `translateY(${current_top}px)` },
                    { transform: `translateY(${new_top}px)` },
                ],
                {
                    // timing options
                    duration: 450,
                    iterations: 1,
                    fill: 'forwards',
                },
            );
            current_top = new_top;

            [...document.getElementsByClassName("text-slide-wrap")].forEach(el => {
                clientHeight = slide.querySelector(`li:nth-child(${counter + 1})`).clientHeight;
                el.style.height = clientHeight ? clientHeight + 'px' : '85px';
            });

            if (++counter === 5) {
                counter = 1;
                current_top = 0;
            }
        }, 5000)
    }

    return (
        <div id={'main-intro'}>
            <Parallax bgImage='/images/berlin_skyline_4k-scaled.webp'
                      bgImageSrcSet={'/images/berlin_skyline_4k-scaled_25.webp 640w, /images/berlin_skyline_4k-scaled_50.webp 1250w, /images/berlin_skyline_4k-scaled_75.webp 1920w, /images/berlin_skyline_4k-scaled.webp 3840w'}
                      className={'parallax-window'}>
                <div className={'pattern'}>
                    <div className={'flex-container'}>

                        <div className={'text-slide-wrap'}>
                            <ul id='text-slider' className="text-slide-vertical">
                                <li>Work with experts</li>
                                <li>Make your life easier</li>
                                <li id={'widest-slide'}>Plan - Build - Maintain</li>
                                <li>Solutions with fit</li>

                                <li className={'slider-clone' /* Needs to be same as 1 */}>Work with experts</li>
                            </ul>
                        </div>

                        <div className={'main-intro-logo'}>
                            <img
                                src="/images/bsolut_logo.webp"
                                width={"250px"}
                                height={'41px'}
                                alt={'logo'}/>
                        </div>
                    </div>
                </div>
            </Parallax>
        </div>
    )
}
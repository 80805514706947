import React from 'react'
import Image from "next/image";

export default function Contact() {
  return (
    <>
      <section id="contact" className={'content-wrap dark'}>
        <div className={'content'}>
          <div className={'circle'}>
            <Image width={32} height={34} src={"/images/icons/phone.svg"} alt={'phone'} />
          </div>

          <a className={'content-title clickable'} href="tel:+49 30 955 930 50">+49 30 955 930 50</a>

          <div className={'icon-text'}>
            <div> <Image width={28} height={22} src={"/images/icons/envelope.svg"} alt={'envelope'} /> </div>
            <a href="mailto:info@bsolut.com">info@bsolut.com</a>
          </div>

          <div className={'icon-text'}>
            <div> <Image width={22} height={28} src={"/images/icons/location-dot.svg"} alt={'location-dot'} /> </div>
            <span>Senefelderstraße 22, 10437 Berlin, Germany</span>
          </div>
        </div>
      </section>

      <section id="contact-shoutout" className={'content-wrap'}>
        <div className={'content'}>
          <span className="border"/>
          <p>
            Want to say hello? Want to know more about us? Give us a call or drop us an email and we will get back to you as soon as we can.
          </p>
        </div>
      </section>
    </>
  )
}
import React from 'react'

export default function Create() {
  return (
    <section id="create" className={'content-wrap'}>
      <div className={'content'}>
          <h1 className={'content-title'}>Create what you want &#8211; leveraged by us</h1>
          <span className="border"/>
          <p>
            We offer a full service for our clients to realize their vision. A highly specialized developer team brings
            fiction to reality. We know when it’s time to go for the newest technology on the market, or to stick to what’s
            been proven right, to get the best possible outcome. <br/><br/> <strong>Our solution is your competitive
            advantage. </strong> <br/><br/> Creating a company is the hardest thing. As StartUp builders our consultants
            work side-by-side with your team, set up processes, help with legal requirements and offer the network every new
            company needs. <br/><br/> <strong>We increase your odds of success. </strong>
          </p>
      </div>
    </section>
  )
}